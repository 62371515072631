import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import makeStyles from '@material-ui/styles/makeStyles'

import CustomText from './CustomText'
import Icon from './Icon'

import { theme, ICONS, SPACING } from '../Theme'
import { getPrecision } from '../utils/common'

export const CURRENCY_SYM = <span style={{ fontFamily: 'sans-serif' }}>{'\u20B9'}</span>

/**
 * @augments {Component<Props,State>}
 */
const ShowLTP = React.memo(({
  change = 0,
  ltp,
  day_open_price,
  seg_sym,
  containerStyles,
  ltpStyles,
  changeStyles,
  disabled,
  changeContainerStyles,
  hideChange,
  showLtpColor,
  hideChangeColor,
  hideChangeIcon,
  showBrackets,
  iconSize,
  hideCurrency,
  showLtpIcon,
  ltpIconProps,
  showLtp,
  customChange,
  customLtp,
  customPointChange,
  showPointChange,
  selectCustomValues,
}) => {
  const styles = styleheet()

  // const [staticChange, setStaticChange] = React.useState(change)
  // const [staticLtp, setStaticLtp] = React.useState(ltp)

  // useEffect(() => {
  //   if(staticChange === 0) {
  //     setStaticChange(change)
  //     setStaticLtp(ltp)
  //   }
  // }, [change, ltp])

  let finalLtp = ltp || customLtp
  let finalChange = change || customChange

  const findNiftyPointChange = () => {
    if(showPointChange) {
      const pointChange = day_open_price ? day_open_price - finalLtp : customPointChange
      return pointChange.toFixed(2)
    }
    return 0
  }

  let niftyPointChange = findNiftyPointChange()

  if(selectCustomValues) {
    finalLtp = customLtp > 0 ? customLtp : ltp
    finalChange = customLtp > 0 ? customChange : change
    niftyPointChange = customLtp > 0 ? customPointChange : niftyPointChange
  }

  if (finalChange === -100) {
    finalChange = 0
  }

  const [segment = '', symbol = ''] = seg_sym.split('_')
  const precision = seg_sym ? getPrecision(`${segment.toUpperCase()}_${symbol}`, segment.toLowerCase()) : 2
  const currency = hideCurrency ? '' : CURRENCY_SYM
  let color = 'text'
  let ltpColor = 'text'
  let ltpIconColor = 'text'
  const icon_name = finalChange < 0 ? ICONS.DOWN_ARROW : ICONS.UP_ARROW
  const ltpIconName = finalChange < 0 ? ICONS.DOWN_HEAD_FILLED : ICONS.UP_HEAD_FILLED

  if (showLtpColor && showLtp) {
    ltpColor = finalChange < 0 ? 'red' : 'green'
    ltpIconColor = ltpColor
  }
  if (!hideChangeColor) {
    color = finalChange < 0 ? 'red' : 'green'
  }

  if (disabled) {
    color = 'textSecondary'
    ltpColor = 'textSecondary'
  }
  let ltpValue = (+finalLtp).toFixed(precision) || finalLtp || 'N/A'
  ltpValue = ltpValue !== 'N/A' && currency ? (
    <>
      {currency}
      {` ${ltpValue}`}
    </>
  ) : ltpValue
  let changeValue = finalChange > 0 ? `+${(+finalChange).toFixed(2)}%` : `${(+finalChange).toFixed(2)}%`
  changeValue = showBrackets ? `(${changeValue})` : changeValue
  return (
    <div className={classnames(styles.container, containerStyles)}>
      <div className={styles.ltpContainer}>
        {showLtpIcon && finalChange !== 0 && (
        <Icon
          name={ltpIconName}
          size={8}
          color={theme[ltpIconColor]}
          style={{ marginRight: SPACING.SPACE_4, marginTop: SPACING.SPACE_2 }}
          {...ltpIconProps(finalChange)}
        />
        )}
        {showLtp && (
        <CustomText
          className={classnames(styles.ltp, ltpStyles)}
          style={{ color: theme[ltpColor] }}
        >
          {ltpValue !== 'N/A' ? ltpValue : 'N/A'}
        </CustomText>
        )}
      </div>
      {showPointChange && (
        <div className={classnames(styles.changeContainer, changeContainerStyles)}>
          <Icon
            name={ltpIconName}
            size={8}
            color={theme[color]}
            style={{
              marginTop: SPACING.SPACE_2,
              marginLeft: SPACING.SPACE_8,
            }}
          />
          <CustomText
            style={{ color: theme[color] }}
            className={classnames(styles.changeValue, changeStyles)}
            size="small_0"
          >
            {Math.abs(niftyPointChange).toFixed(2)}
          </CustomText>
        </div>
      )}
      {!(hideChange && hideChangeIcon) ? (
        <div className={classnames(styles.changeContainer, changeContainerStyles)}>
          {!hideChangeIcon ? (
            <Icon
              style={{ marginBottom: 3, marginLeft: SPACING.SPACE_8 }}
              name={icon_name}
              size={iconSize}
              color={theme[color]}
            />
          ) : null}
          {!hideChange ? (
            <CustomText
              className={classnames(styles.changeValue, changeStyles)}
              style={{ color: theme[color] }}
              size="small_0"
            >
              {changeValue}
            </CustomText>
          ) : null}
        </div>
      ) : null}
    </div>
  )
})

const styleheet = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  ltpContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  changeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // marginLeft: SPACING.SPACE_8,
  },
  changeValue: {
    marginLeft: SPACING.SPACE_4,
  },
  ltp: {
    whiteSpace: 'nowrap',
  },
}))

const mapStateToProps = (state, ownProps) => {
  const { ticks } = state.instruments
  const { seg_sym, is52week } = ownProps
  let change = 0
  let ltp = 0
  let day_open_price = 0
  if(seg_sym && ticks[seg_sym]) {
    ({ change, price: ltp, day_open_price } = ticks[seg_sym])
  }
  if(is52week) {
    return {
      ltp,
    }
  }
  return {
    change,
    ltp,
    day_open_price,
  }
}

ShowLTP.defaultProps = {
  containerStyles: '',
  changeContainerStyles: '',
  ltpStyles: '',
  changeStyles: '',
  disabled: false,
  showLtpColor: true,
  hideChangeColor: false,
  hideChange: false,
  hideChangeIcon: true,
  hideCurrency: false,
  showBrackets: false,
  iconSize: 12,
  ltpIconProps: () => {},
  showLtpIcon: false,
  showLtp: true,
}

ShowLTP.propTypes = {
  seg_sym: PropTypes.string.isRequired,
  containerStyles: PropTypes.string,
  changeContainerStyles: PropTypes.string,
  ltpStyles: PropTypes.string,
  changeStyles: PropTypes.string,
  disabled: PropTypes.bool,
  showLtpColor: PropTypes.bool,
  hideChangeColor: PropTypes.bool,
  hideChange: PropTypes.bool,
  hideChangeIcon: PropTypes.bool,
  hideCurrency: PropTypes.bool,
  showBrackets: PropTypes.bool,
  iconSize: PropTypes.number,
  ltpIconProps: PropTypes.func,
  showLtpIcon: PropTypes.bool,
  showLtp: PropTypes.bool,
}

export default connect(mapStateToProps)(ShowLTP)

/*
  How to Use
  <ShowLTP
    containerStyle={classes.ltpContainer}
    ltpStyle={classes.ltp}
    changeStyle={classes.changeValue}
    segSym={seg_sym}
  />
*/
